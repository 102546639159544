






















import {Vue, Component} from "vue-property-decorator";

@Component({
  name: "Settings",
})

export default class Settings extends Vue {

}
